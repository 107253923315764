<script>
  import { afterUpdate } from "svelte";

  export let errors;

  let errorHeading;

  afterUpdate(() => {
    if (errorHeading) {
      errorHeading.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  });
</script>

{#if errors && errors.hasOwnProperty("error")}
  <div class="row">
    <div class="columns">
      <div data-alert class="alert-box alert" tabindex="0" role="alert">
        <h3 bind:this={errorHeading}>Error</h3>
        {#each Object.values(errors.error) as error}
          <span>{@html error}</span><br />
        {/each}
      </div>
    </div>
  </div>
{/if}

<style>
  h3 {
    color: white;
  }
</style>
