<script>
  import PreForm from "./PreForm.svelte";
  import Errors from "./Errors.svelte";
  import Form from "./Form.svelte";
  import Results from "./Results.svelte";

  let people;
  let details;
  let errors;
  let formDisabled = true;
  let loadingPeople = false;

  function getPeople(event) {
    // Resets people and details when main search occurs
    loadingPeople = true;
    people = undefined;
    details = undefined;
    errors = undefined;

    // Begin XHR Request
    var formData = new FormData(event.target);
    var xhr = new XMLHttpRequest();
    var url = "ajax/get_people.php";
    xhr.open("post", url);

    // Send XHR
    xhr.send(formData);

    // Reset captcha
    grecaptcha.reset();
    disableSubmit();

    // Handle response
    xhr.onload = function () {
      // console.log(this.response);
      var response = JSON.parse(this.response);
      people = response[0];
      errors = response[1];
      loadingPeople = false;
      // people = response;
      // console.log(response);
    };
  }

  function updateDetails(event) {
    errors = undefined;
    details = event.detail.response[0];
    errors = event.detail.response[1];
  }

  function enableSubmit() {
    formDisabled = false;
  }

  function disableSubmit() {
    formDisabled = true;
  }

  grecaptcha.ready(() => {
    grecaptcha.render("g-recaptcha", {
      sitekey: "6LcHowYbAAAAAF44vqWR_AspIs1RLhCzNFHS7kVs",
      callback: enableSubmit,
      "expired-callback": disableSubmit,
    });
  });
</script>

<!-- <svelte:head>
  {#if !window.jQuery}
    <script
      src="https://cdnjs.cloudflare.com/ajax/libs/jquery/1.12.4/jquery.min.js"
      integrity="sha512-jGsMH83oKe9asCpkOVkBnUrDDTp8wl+adkB2D+//JtlxO4SrLoJdhbOysIFQJloQFD+C4Fl1rMsQZF76JjV0eQ=="
      crossorigin="anonymous"
      referrerpolicy="no-referrer">
    </script>
  {/if}
  {#if !jQuery(document).foundation()}
    <script
      src="https://cdnjs.cloudflare.com/ajax/libs/foundation/5.5.2/js/foundation.min.js"
      integrity="sha512-bhvQHyjLd5JTsfbIziJwez8eHhjwi687gJS+uZ41WpjM95arw07kQ1nQUEHPDvPeigKhfnjpG2TY2v/gKjlXHQ=="
      crossorigin="anonymous"
      referrerpolicy="no-referrer">
    </script>
    <link
      rel="stylesheet"
      href="https://cdnjs.cloudflare.com/ajax/libs/foundation/5.5.2/css/foundation.min.css"
      integrity="sha512-doy0PJIwvGRj/TWyaxLNOL43Yy2INfFuL/7t2RS5bugFxTevfEX5CDnSXLuBxjOG63N9avh/vNs9XjsQbuaaMQ=="
      crossorigin="anonymous"
      referrerpolicy="no-referrer"
    />
    <link
      rel="stylesheet"
      href="https://cdnjs.cloudflare.com/ajax/libs/foundation/5.5.2/css/normalize.min.css"
      integrity="sha512-Ojqt7YpXqYM6//AdMhErV3ot38rYgGF5QLJEwx7zhesjL9VqfhWiRz/dWK22hsn96RNz0CLl85+pg1P0BmfgVQ=="
      crossorigin="anonymous"
      referrerpolicy="no-referrer"
    />
  {/if}
</svelte:head> -->

<PreForm />
<Errors {errors} />
<Form on:submit={getPeople} {formDisabled} />
<Results {people} {details} {loadingPeople} on:newDetails={updateDetails} />

<style>
  :global(*:focus) {
    outline: #23e6c9 auto 4px !important;
    outline-offset: 0 !important;
  }
  :global(html) {
    scroll-behavior: smooth;
  }
</style>
