<script>
  import People from "./People.svelte";
  import Details from "./Details.svelte";
  import { createEventDispatcher } from "svelte";

  const dispatch = createEventDispatcher();

  export let people;
  export let details;
  export let errors;
  export let loadingPeople;

  let loadingDetails = false;

  function getDetails(event) {
    // console.log(boxId);
    details = false;
    loadingDetails = true;
    let boxId = event.detail;

    grecaptcha.ready(function () {
      grecaptcha
        .execute("6LcmlA0bAAAAAPYOYDG3p_p_ViaRO78zUmmCV_ek", {
          action: "getDetails",
        })
        .then(function (token) {
          var xhr = new XMLHttpRequest();
          var url = "ajax/get_details.php";
          xhr.open("post", url);
          xhr.setRequestHeader("Content-Type", "application/json");

          // Send XHR
          xhr.send(
            JSON.stringify({
              "g-recaptcha-response": token,
              boxId: boxId,
            })
          );

          // Handle response
          xhr.onload = function () {
            var response = JSON.parse(this.response);
            // console.log(response);
            dispatchDetails(response);
            loadingDetails = false;
          };
        });
    });
  }

  function dispatchDetails(response) {
    dispatch("newDetails", {
      response,
    });
  }
</script>

<section>
  <div class="row">
    <hr />
    <People {people} {loadingPeople} on:personClicked={getDetails} />
    <Details {details} {errors} {loadingDetails} />
  </div>
</section>

<style>
  section {
    margin-bottom: 10rem;
  }
</style>
