<script>
  export let formDisabled;
</script>

<div class="row">
  <div class="small-24 columns">
    <form
      id="people-search-form"
      method="post"
      data-abide
      on:submit|preventDefault
    >
      <div class="row">
        <div class="column">
          <fieldset>
            <legend>Instructions</legend>
            <p class="small" id="form-instructions">
              All fields are required (*).
            </p>
          </fieldset>
        </div>
      </div>
      <div class="row">
        <div class="large-12 columns">
          <fieldset>
            <legend>Name*</legend>
            <label>
              First Name
              <input
                type="text"
                name="firstName"
                aria-describedby="name-help-text"
                pattern="regexNames"
              />
            </label>
            <label>
              Last Name
              <input
                type="text"
                name="lastName"
                aria-describedby="name-help-text"
                pattern="regexNames"
              />
            </label>
            <small class="error">Please enter a valid search</small>
            <div id="name-help-text" class="small">
              Search Requirements:
              <ul class="small">
                <li>No special characters or numbers</li>
                <li>2 character minimum</li>
              </ul>
            </div>
          </fieldset>
        </div>
        <div class="large-12 columns">
          <fieldset>
            <legend>Affiliation*</legend>
            <input
              type="radio"
              name="audience"
              value="Faculty"
              id="faculty"
              required
              aria-required="true"
            /><label for="faculty">Faculty</label>
            <input
              type="radio"
              name="audience"
              value="Staff"
              id="staff"
              required
              aria-required="true"
            /><label for="staff">Staff</label>
          </fieldset>
          <fieldset>
            <legend>Captcha*</legend>
            <div id="g-recaptcha" />
          </fieldset>
        </div>
      </div>
      <!-- <div class="row">
        <div class="large-12 column" />
      </div> -->
      <br />
      <input
        id="submit"
        type="submit"
        class={formDisabled ? "disabled button" : "button"}
        disabled={formDisabled}
        value="Search"
      />
    </form>
  </div>
</div>

<style>
  .small {
    font-size: 0.9rem;
  }
  #form-instructions {
    margin-bottom: 0;
  }
</style>
