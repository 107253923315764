import App from './App.svelte';

$(document)
  .foundation({
    abide: {
      patterns: {
        regexNames: /^([a-zA-Z\-\.\']{2,}[\s]*)*$/
      }
    }
  });

const app = new App({
  target: document.getElementById("app-form"),
  props: {}
});

export default app;