<div class="row">
  <div class="column">
    <h1>People Search</h1>
    <br />
    <p>
      Use our people directory search to look up a Wake Forest staff or faculty
      member. This directory does not contain listings from the Medical School.
    </p>
    <p>
      To access student information and more, log in to the <a
        href="https://contacts.google.com/directory"
        >Internal Directory</a
      > with your WFU Account.
    </p>
    <p>
      For more information, please visit the <a
        href="https://about.wfu.edu/directories/">Directories</a
      > page.
    </p>
    <h2>Search Form</h2>
  </div>
</div>
