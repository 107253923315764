<script>
  import Loading from "./Loading.svelte";
  import { createEventDispatcher } from "svelte";
  import { afterUpdate } from "svelte";

  const dispatch = createEventDispatcher();

  export let people;
  export let loadingPeople;

  let results;

  afterUpdate(() => {
    if (results) {
      results.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  });
</script>

<div class="small-24 medium-12 columns" aria-live="polite">
  {#if loadingPeople}
    <Loading />
  {/if}
  {#if people && people.length}
    <h2 bind:this={results}>Results</h2>
    <ul>
      {#each people as person (person.boxId)}
        <li>
          <button on:click={() => dispatch("personClicked", person.boxId)}>
            {person.fullName}
          </button>
        </li>
      {/each}
    </ul>
  {/if}
</div>

<style>
  ul {
    list-style-type: none;
    margin-left: 0;
  }
  button {
    all: unset;
    color: #8c6d2c;
    cursor: pointer;
  }
  button:hover {
    color: #000000;
  }
</style>
