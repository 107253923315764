<script>
  import Loading from "./Loading.svelte";

  export let details;
  export let loadingDetails;

  function formatPhone(formattedPhone) {
    const unformattedPhone = formattedPhone.replace(/[^+\d]+/g, "");
    return unformattedPhone;
  }
</script>

<div class="small-24 medium-12 columns" aria-live="polite">
  {#if loadingDetails}
    <Loading />
  {/if}
  {#if details}
    {#if details.fullName}
      <h3>
        {details.fullName}
      </h3>
    {/if}

    {#if details.email}
      <div>
        <h4>Email:</h4>
        <a href="mailto:{details.email}">{details.email}</a>
      </div>
    {/if}

    {#if details.title}
      <div>
        <h4>Title:</h4>
        {details.title}
      </div>
    {/if}

    {#if details.department}
      <div>
        <h4>Department:</h4>
        {details.department}
      </div>
    {/if}

    {#if details.officePhone}
      <div>
        <h4>Office Phone:</h4>
        <a href="tel:{formatPhone(details.officePhone)}"
          >{details.officePhone}</a
        >
      </div>
    {/if}
  {/if}
</div>

<style>
  h4 {
    font-size: 1rem;
    display: inline-block;
  }
</style>
